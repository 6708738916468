<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Backup Specialist Ejaf Technology</h2>
      <p class="desc my-3">
        The advanced persistent threat, which is also termed a targeted threat,
        is a type of attack following the footsteps of a unique and legible
        model to become successful. Advanced Persistent-Threat is not just a
        mere piece of malware but also a technology used in different
        operations. And when it comes to the model of APT, it provides
        enterprise security solutions. It is a fully secure framework that also
        has intelligence sharing to see known and unknown threats. The APT
        fabric consists of many different components, including entryways,
        terminations points, and sandboxing used to encounter the terrors beyond
        the spectrum of Information technology. The model of threat makes sure
        that the attacks are decreased, and the firm becomes stronger.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/enterprise-security-solutionsimg-1.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-enter-Sec-section",
};
</script>
