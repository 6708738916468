<template>
  <enterprise-security />
</template>

<script>
import EnterpriseSecurity from "../../components/solutions/cyber-security/EnterpriseSecurity.vue";
export default {
  components: { EnterpriseSecurity },
};
</script>
