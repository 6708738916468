<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1 mb-3">Cutting edge Firewalls</h2>
      <p class="desc my-3">
        This cutting edge firewall is used to handle networks and internet
        traffic. It is done by using the specific application. They are also
        used in the detection of application-specific attacks, thereby
        decreasing it. It can be either hardware-based or software-based with
        specific policies and a mixture of protocols and the port that damages
        the seventh layer. Many characteristics are offered by cutting edge
        firewalls, including Stateful investigation, application’s
        understanding, knowledge about the user, Intrusion Protection System,
        multi-purpose modes, and capacity to use the latest updates from insight
        resources.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/enterprise-security-solutionsimg-4.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fivth-enter-Sec-section",
};
</script>
