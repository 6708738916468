<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1 mb-3">
        End Point Protection Against The Threats
      </h2>
      <p class="desc my-3">
        Firms must adopt long-lasting protection and high-security measures
        against the prevailing attacks and threats. Endpoint protection provides
        the best enterprise security solutions by providing safety against
        malware threats, internet traffic, and spamming, stealing, and other web
        threats. Moreover, it provides a high level and tight security for
        electronic gadgets, including PC(s), mobile phones, tablets, laptops,
        and VDI infrastructure protecting against stealing and losing data.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/endpoint-security-products-features.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "nineth-enter-Sec-section",
};
</script>
