<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="h1 title-orange">Solutions Provided by Us</h2>
    <p class="my-5">
      EJAF provides advanced network security solutions for enterprises. We are
      trying our best to be one of the top security solution integrators by
      working on expertise and by obtaining an experimental experience. EJAF has
      a partnership with highly qualified, skilled engineers and sellers from
      leading industries that have experience in handling organized enforced
      networks and infrastructures.
    </p>
  </div>
</template>

<script>
export default {
  name: "first-enter-Sec-section",
};
</script>
