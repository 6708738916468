<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Security & Vulnerability Assessment</h2>
      <p class="desc my-3">
        Powerlessness evaluation is an inside review of an association’s system
        and framework security, the consequences of which demonstrate the
        classification, accessibility, and respectability of the system parts.
        Weakness Assessment can likewise be named as the procedure followed to
        distinguish insufficiently made sure about PCs or potentially arranges.
        Evaluations, likewise, by and large, incorporate numerous methodologies
        for organizing and applying extra security activities for fixing and
        protecting frameworks from dangers. Ordinary weakness appraisals make a
        preemptive spotlight on by and large data security and help recognize
        likely adventures before programmers discover them. The powerlessness
        evaluations help disentangle [PCIDSS], [SOX], and [HIPAA] consistency or
        decreases related expenses.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/enterprise-security-solutionsimg-15.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-enter-Sec-section",
};
</script>
