<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1 mb-3">Backup Specialist Ejaf Technology</h2>
      <p class="desc my-3">
        The Web applications ensure the security and privacy of all three layers
        of a website: the website, web server, and the database server.
        Centralize web applications, terror-free firms, customized applications
        services are provided by Web application Firewalls(WAF), which is the
        foremost enterprise security solutions. Moreover, by using different
        schemes, WAF protects from dangers like [SQL] injections, hacking of
        cookies, scamming, and further possible web attacks.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/enterprise-security-solutionsimg-2.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-enter-Sec-section",
};
</script>
