<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Application Delivery Controllers</h2>
      <p class="desc my-3">
        Firms using advanced applications like Microsoft, IBM, Oracle, SAP, or
        other famous sellers are required to deliver continuous accessibility,
        smooth and easy access even from different locations, and have increased
        network security for firms. If you have difficulty in delivering this
        quality, then the best solution is an application delivery controller.
        It provides the capability to handle a load and accessibility and
        security to all the internet-based applications. The propelled
        application conveyance controllers offer basic server farm capacities,
        such as layers ranging from four to seven burden adjusting, application
        speeding up, proactive application well-being checks, security socket
        layers off-loading, and DDOS assurance, and [DNS] firewalls.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/enterprise-security-solutionsimg-3.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-enter-Sec-section",
};
</script>
