<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">
        Ultimate Benefits Of Malware Protection
      </h2>
      <p class="desc my-3">
        Malware is a compact of malicious-programming intended to get entrance,
        steal the information, and harm PC without bringing in a notice of
        holder. Many different sorts of malware are there, including infections,
        Worms, dim products, spy products, and any pass-code that has a bad
        impact on PC. The Protection of Malware arrangement guarantees
        consistent safety for actions that are done by clients accessing any app
        at any gadget, and wherever including protective assurance at odds with
        infections, Trojans, worms, spyware, emancipate product, and the latest
        variations that develop normally. Associated danger safeguard conveys
        quick reaction empowering quicker an ideal opportunity to-security and
        lessening the spread of malware.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/enterprise-security/malware.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eightth-enter-Sec-section",
};
</script>
